@charset "UTF-8";
@import "mixin";

/* グローバルヘッダ */
.gl_header {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  min-width:980px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding:0;
  z-index: 100;
  background-color: #fff;
  box-sizing:border-box;
  @include sp {
    min-width:0;
    height:50px;
  }
  &--logo {
    display:block;
    margin: auto auto auto 30px;
    outline:none;
    @include sp{
      margin-left:16px;
    }
    & img{
      width: 320px;
      @include sp{
        width:210px;
      }
    }
  }
  &--btn {
    position: relative;
    display: inline-flex;
    justify-content:center;
    align-items:center;
    min-width:155px;
    height:44px;
    box-sizing:border-box;
    text-align: center;
    letter-spacing:-0.06em;
    padding: 0;
    margin:auto 0 auto 10px;
    border:1px solid $base-red;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 12px;
    line-height:14px;
    font-weight: 500;
    color: $base-red;
    text-decoration: none;
    background-color: #fff;
    transition: .3s;
    &:after {
      @include icon;
      content:$icon-arrow-right;
      font-size:10px;
      line-height:1;
      position: absolute;
      top: calc(50% - 5px);
      right: 10px;
    }
    &:hover {
        background-color: $base-red;
        color:#fff;
    }
    @include sp{
      display:none;
    }
  }
  &--tel {
    color:#000;
    font-size:24px;
    line-height:1;
    font-family:'Anton';
    text-decoration:none;
    margin:0 30px;
    &:before{
      @include icon;
      content: $icon-tel;
      color: black;
      font-size: 26px;
      line-height:1;
      vertical-align:middle;
      margin-right:6px;
    }
    @include sp{
      display:none;
    }
  }
  &--nav_btn{
    position: relative;
    display: inline-block;
    width: 64px;
    height: 64px;
    background-color: $base-red;
    cursor: pointer;
    transition:opacity .2s linear;
    border:none;
    padding:0;
    &:before,
    &:after {
      content:"";
      position: absolute;
      right:18px;
      background-color:#fff;
      height: 2px;
      transform-origin:center center;
      transition:all .2s linear;
      @include sp{
        right:13px;
      }
    }
    &:before {
      width: 29px;
      top:25px;
      @include sp{
        width:24px;
        top:21px;
      }
    }
    &:after {
      width: 15px;
      top:35px;
      @include sp{
        width:14px;
        top:27px;
      }
    }
    &:hover{
      opacity:.8;
    }
    &.opened{
      &:before{
        top:33px;
        transform:rotate(45deg);
        @include sp{
          top:25px;
        }
      }
      &:after{
        top:33px;
        width:29px;
        transform:rotate(-45deg);
        @include sp{
          top:25px;
          width:24px;
        }
      }
    }
    @include sp{
      width:50px;
      height:50px;
    }
  }
  &--staff{display:none;}
}

/* メニュー代替版 */
/* 上の階層にmenu_altクラスがある場合に追加のボタンを表示 */
.menu_alt .gl_header{
  @media(max-width:1300px){
    &--download{display:none;}
  }
  &--staff{
    display:flex;
    @include sp{
      display:none;
    }
  }
}
