@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Anton|Lato:300|Noto+Sans+JP:400,500|Teko:500&display=swap");
@font-face {
  font-family: "iconfont";
  src: url("/common/fonts/iconfont.eot");
  src: url("/common/fonts/iconfont.eot?#iefix") format("eot"), url("/common/fonts/iconfont.woff2") format("woff2"), url("/common/fonts/iconfont.woff") format("woff"), url("/common/fonts/iconfont.ttf") format("truetype"), url("/common/fonts/iconfont.svg#iconfont") format("svg");
}

/* グローバルヘッダ */
.gl_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 980px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  z-index: 100;
  background-color: #fff;
  box-sizing: border-box;
}

@media (max-width: 425px) {
  .gl_header {
    min-width: 0;
    height: 50px;
  }
}

.gl_header--logo {
  display: block;
  margin: auto auto auto 30px;
  outline: none;
}

@media (max-width: 425px) {
  .gl_header--logo {
    margin-left: 16px;
  }
}

.gl_header--logo img {
  width: 320px;
}

@media (max-width: 425px) {
  .gl_header--logo img {
    width: 210px;
  }
}

.gl_header--btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 155px;
  height: 44px;
  box-sizing: border-box;
  text-align: center;
  letter-spacing: -0.06em;
  padding: 0;
  margin: auto 0 auto 10px;
  border: 1px solid #c80023;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: #c80023;
  text-decoration: none;
  background-color: #fff;
  transition: .3s;
}

.gl_header--btn:after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-decoration: none;
  text-transform: none;
  content: "";
  font-size: 10px;
  line-height: 1;
  position: absolute;
  top: calc(50% - 5px);
  right: 10px;
}

.gl_header--btn:hover {
  background-color: #c80023;
  color: #fff;
}

@media (max-width: 425px) {
  .gl_header--btn {
    display: none;
  }
}

.gl_header--tel {
  color: #000;
  font-size: 24px;
  line-height: 1;
  font-family: 'Anton';
  text-decoration: none;
  margin: 0 30px;
}

.gl_header--tel:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-decoration: none;
  text-transform: none;
  content: "";
  color: black;
  font-size: 26px;
  line-height: 1;
  vertical-align: middle;
  margin-right: 6px;
}

@media (max-width: 425px) {
  .gl_header--tel {
    display: none;
  }
}

.gl_header--nav_btn {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 64px;
  background-color: #c80023;
  cursor: pointer;
  transition: opacity .2s linear;
  border: none;
  padding: 0;
}

.gl_header--nav_btn:before, .gl_header--nav_btn:after {
  content: "";
  position: absolute;
  right: 18px;
  background-color: #fff;
  height: 2px;
  transform-origin: center center;
  transition: all .2s linear;
}

@media (max-width: 425px) {
  .gl_header--nav_btn:before, .gl_header--nav_btn:after {
    right: 13px;
  }
}

.gl_header--nav_btn:before {
  width: 29px;
  top: 25px;
}

@media (max-width: 425px) {
  .gl_header--nav_btn:before {
    width: 24px;
    top: 21px;
  }
}

.gl_header--nav_btn:after {
  width: 15px;
  top: 35px;
}

@media (max-width: 425px) {
  .gl_header--nav_btn:after {
    width: 14px;
    top: 27px;
  }
}

.gl_header--nav_btn:hover {
  opacity: .8;
}

.gl_header--nav_btn.opened:before {
  top: 33px;
  transform: rotate(45deg);
}

@media (max-width: 425px) {
  .gl_header--nav_btn.opened:before {
    top: 25px;
  }
}

.gl_header--nav_btn.opened:after {
  top: 33px;
  width: 29px;
  transform: rotate(-45deg);
}

@media (max-width: 425px) {
  .gl_header--nav_btn.opened:after {
    top: 25px;
    width: 24px;
  }
}

@media (max-width: 425px) {
  .gl_header--nav_btn {
    width: 50px;
    height: 50px;
  }
}

.gl_header--staff {
  display: none;
}

/* メニュー代替版 */
/* 上の階層にmenu_altクラスがある場合に追加のボタンを表示 */
@media (max-width: 1300px) {
  .menu_alt .gl_header--download {
    display: none;
  }
}

.menu_alt .gl_header--staff {
  display: flex;
}

@media (max-width: 425px) {
  .menu_alt .gl_header--staff {
    display: none;
  }
}

/* footer */
.gl_footer {
  position: relative;
  width: 100%;
  min-width: 980px;
  color: #fff;
  background: linear-gradient(0deg, #414144 0%, black 37.07%);
  padding: 100px 0 50px;
}

@media (max-width: 425px) {
  .gl_footer {
    min-width: 0;
    padding: 50px 28px 120px;
    box-sizing: border-box;
  }
}

@media (max-width: 320px) {
  .gl_footer {
    padding: 40px 20px 120px;
  }
}

.gl_footer--panels {
  width: 960px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 auto 86px;
}

@media (max-width: 425px) {
  .gl_footer--panels {
    justify-content: center;
    width: 100%;
    margin-bottom: 66px;
  }
}

.gl_footer--panels li {
  margin-bottom: 48px;
}

@media (max-width: 425px) {
  .gl_footer--panels li {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 425px) {
  .gl_footer--item01 {
    order: 1;
  }
  .gl_footer--item02 {
    order: 2;
  }
  .gl_footer--item03 {
    order: 4;
  }
  .gl_footer--item04 {
    order: 3;
  }
  .gl_footer--item04wide {
    order: 3;
    width: auto;
  }
}

.gl_footer--panel {
  position: relative;
  color: inherit;
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 450px;
  height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  /*border:8px solid transparent;*/
  outline: 8px solid transparent;
  outline-offset: -8px;
  box-sizing: border-box;
  transition: all .2s linear;
  overflow: hidden;
}

@media (max-width: 425px) {
  .gl_footer--panel {
    font-size: 18px;
    line-height: 24px;
    width: auto;
    height: 120px;
    outline-offset: -4px;
    outline-width: 4px;
  }
}

.gl_footer--panel:hover {
  /*border-color:$base-red;*/
  outline-color: #c80023;
}

.gl_footer--panel:after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-decoration: none;
  text-transform: none;
  content: "";
  font-size: 22px;
  color: #c80023;
  display: block;
  margin: 0 -20px 0 20px;
}

@media (max-width: 425px) {
  .gl_footer--panel:after {
    font-size: 16px;
    margin: 0 -10px 0 10px;
  }
}

.gl_footer--panel-01 {
  background-image: url("/common/images/footer-panel01.jpg");
}

.gl_footer--panel-02 {
  background-image: url("/common/images/footer-panel02.jpg");
}

.gl_footer--panel-03 {
  background-image: url("/common/images/footer-panel03.jpg");
}

.gl_footer--panel-04 {
  background-image: url("/common/images/footer-panel04.jpg");
}

.gl_footer--panel-04wide {
  background-image: url("/common/images/footer-panel04-wide.jpg");
  width: 960px;
}

.gl_footer--info {
  justify-content: space-between;
  width: 960px;
  display: flex;
  align-items: flex-start;
  margin: 0 auto 100px;
}

@media (max-width: 425px) {
  .gl_footer--info {
    width: auto;
    flex-wrap: wrap;
    margin-bottom: 75px;
  }
}

.gl_footer--list {
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 320px) {
  .gl_footer--list {
    font-size: 13px;
  }
}

.gl_footer--list li:not(:last-of-type) {
  margin: 0 0 18px;
}

.gl_footer--list a {
  color: inherit;
  text-decoration: none;
}

.gl_footer--list a:hover {
  text-decoration: underline;
}

.gl_footer--list-sub {
  margin: 24px 0 0;
}

@media (max-width: 425px) {
  .gl_footer--list-sub {
    margin-top: 18px;
  }
}

.gl_footer--list-sub li {
  color: #b3b3b3;
  font-weight: 500;
}

.gl_footer--list-sub li:before {
  content: '- ';
  color: #c80023;
}

@media (max-width: 425px) {
  .gl_footer--list:first-of-type {
    margin-bottom: 45px;
  }
}

.gl_footer--logo {
  text-decoration: none;
  /*margin-top:-10px;*/
  margin-right: 90px;
}

@media (max-width: 425px) {
  .gl_footer--logo {
    margin: 0 90px 40px 0;
  }
}

.gl_footer--logo img {
  height: 70px;
}

@media (max-width: 425px) {
  .gl_footer--logo img {
    height: 50px;
  }
}

.gl_footer--sns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.gl_footer--sns a {
  text-decoration: none;
  margin: 0 15px;
}

.gl_footer--facebook:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-decoration: none;
  text-transform: none;
  content: "";
  font-size: 30px;
  color: #fff;
}

.gl_footer--instagram:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-decoration: none;
  text-transform: none;
  content: "";
  font-size: 30px;
  color: #fff;
}

.gl_footer--line:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-decoration: none;
  text-transform: none;
  content: "";
  font-size: 30px;
  color: #fff;
}

.gl_footer--twitter:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-decoration: none;
  text-transform: none;
  content: "";
  font-size: 30px;
  color: #fff;
}

.gl_footer--copyright {
  width: 100%;
  font-family: 'Lato',sans-serif;
  font-size: 14px;
  font-weight: 200;
  text-align: center;
  margin: 40px 0 0;
}

@media (max-width: 425px) {
  .gl_footer--copyright {
    font-size: 13px;
    margin: 40px -20px 0;
  }
}

@media (max-width: 320px) {
  .gl_footer--copyright {
    font-size: 11px;
  }
}

.gl_footer--pagetop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 48px;
  bottom: 36px;
  width: 90px;
  height: 90px;
  background: #fff;
  border-radius: 45px;
  border: 8px solid #bababa;
  z-index: 2;
  box-sizing: border-box;
  transition: all .3s linear;
  visibility: visible;
  opacity: 1;
}

.gl_footer--pagetop.hidden {
  opacity: 0;
  visibility: hidden;
}

.gl_footer--pagetop:hover {
  border-color: #c80023;
}

.gl_footer--pagetop img {
  width: 31px;
  margin-top: -6px;
}

@media (max-width: 425px) {
  .gl_footer--pagetop img {
    width: 20px;
    margin-top: -3px;
    -webkit-tap-highlight-color: transparent;
  }
}

@media (max-width: 425px) {
  .gl_footer--pagetop {
    right: 16px;
    bottom: 38px;
    width: 56px;
    height: 56px;
    border-width: 5px;
    -webkit-tap-highlight-color: transparent;
  }
  .gl_footer--pagetop:hover {
    border-color: #bababa;
  }
  .gl_footer--pagetop.moved {
    bottom: 108px;
  }
}

/* 採用情報の表示／非表示 */
/* 上位に.norecruitクラスを設定して切替 */
.gl_footer--norecruit-hide {
  display: block;
}

.gl_footer--norecruit-show {
  display: none;
}

.norecruit .gl_footer--norecruit-hide {
  display: none;
}

.norecruit .gl_footer--norecruit-show {
  display: block;
}

/* グローバルナビ */
.gl_nav {
  position: fixed;
  top: 64px;
  right: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all .2s linear;
  z-index: 110;
  box-sizing: border-box;
}

@media (max-width: 425px) {
  .gl_nav {
    top: 50px;
  }
}

.gl_nav.opened {
  height: calc(100vh - 64px);
  width: 100%;
  opacity: 1;
}

@media (max-width: 425px) {
  .gl_nav.opened {
    height: calc(100vh - 50px);
  }
}

.gl_nav.opened .gl_nav--contents {
  visibility: visible;
  width: 100%;
  height: calc(100% - 64px);
}

@media (max-width: 425px) {
  .gl_nav.opened .gl_nav--contents {
    height: calc(100% - 50px);
  }
}

.gl_nav--contents {
  display: flex;
  visibility: hidden;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 64px;
  right: 0;
  width: 100%;
  height: 0;
  background-color: #fff;
  padding-top: 115px;
  box-sizing: border-box;
  transition: all .2s linear;
}

@media (max-width: 425px) {
  .gl_nav--contents {
    flex-direction: column;
    justify-content: flex-start;
    top: 50px;
    padding: 40px 30px 30px;
  }
}

@media (max-width: 320px) {
  .gl_nav--contents {
    padding: 20px;
  }
}

.gl_nav--contents:before, .gl_nav--contents:after {
  /* 背景矢印 */
  content: '';
  position: absolute;
  width: 457px;
  height: 50%;
  left: 50%;
  opacity: .1;
  z-index: 0;
}

.gl_nav--contents:before {
  background: linear-gradient(160deg, #ea617b 0%, #e3425f 17.88%, #dd2545 38.82%, #d91132 59.68%, #d60427 80.2%, #d50023 100%);
  transform-origin: left bottom;
  transform: skew(-124.5deg);
  bottom: 50%;
}

.gl_nav--contents:after {
  background: linear-gradient(50deg, #f2afaf 0%, #f1a3a7 8.14%, #ee8694 31.57%, #ec7286 54.9%, #ea657e 77.85%, #ea617b 100%);
  top: 50%;
  transform-origin: left top;
  transform: skew(124.5deg);
}

.gl_nav--list {
  position: relative;
  z-index: 1;
}

@media (max-width: 425px) {
  .gl_nav--list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-grow: 1;
    margin-bottom: 40px;
    max-height: 250px;
  }
}

.gl_nav--item {
  font-family: 'Noto Sans JP',sans-serif;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 72px;
}

@media (max-width: 425px) {
  .gl_nav--item {
    font-size: 18px;
    margin-bottom: 0;
  }
}

@media (max-width: 320px) {
  .gl_nav--item {
    font-size: 16px;
  }
}

.gl_nav--item-link {
  display: block;
  color: inherit;
  text-decoration: none;
}

.gl_nav--item-link:hover {
  text-decoration: underline;
}

.gl_nav--right {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin-left: 115px;
  z-index: 1;
}

@media (max-width: 425px) {
  .gl_nav--right {
    margin-left: 0;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.gl_nav--txt {
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  text-align: center;
  margin: 32px 0;
  order: 2;
}

@media (max-width: 425px) {
  .gl_nav--txt {
    margin: 20px 0 0;
    order: 3;
    text-align: left;
  }
}

.gl_nav--tel {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  font-family: 'Anton',sans-serif;
  font-size: 34px;
  line-height: 1;
  margin: 18px 0;
}

@media (max-width: 425px) {
  .gl_nav--tel {
    margin: 18px 0 8px;
  }
}

.gl_nav--btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 221px;
  min-height: 51px;
  color: #c80023;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  letter-spacing: -0.06em;
  padding: 16px 0;
  margin: 0;
  border: 1px solid #c80023;
  background-color: #fff;
  box-sizing: border-box;
  transition: .3s;
}

@media (max-width: 425px) {
  .gl_nav--btn {
    font-size: 14px;
    min-width: 0;
    min-height: 62px;
    width: 48%;
  }
}

@media (max-width: 320px) {
  .gl_nav--btn {
    font-size: 13px;
    min-height: 54px;
    padding: 10px 0;
    width: 49%;
  }
}

.gl_nav--btn:after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-decoration: none;
  text-transform: none;
  content: "";
  font-size: 14px;
  display: block;
  position: absolute;
  top: calc(50% - 7px);
  right: 10px;
  vertical-align: middle;
}

@media (max-width: 320px) {
  .gl_nav--btn:after {
    font-size: 12px;
    top: calc(50% - 6px);
    right: 6px;
  }
}

.gl_nav--btn:hover {
  background-color: #c80023;
  color: #fff;
}

.gl_nav--btn-double {
  font-size: 12px;
  line-height: 14px;
}

.gl_nav--btn-maeq {
  display: none;
}

.gl_nav--btn-neo {
  order: 1;
}

.gl_nav--btn-contact {
  order: 3;
}

.gl_nav--btn-recruit {
  display: none;
}

.gl_nav--btn-download {
  display: none;
  margin-bottom: 10px;
}

@media (max-width: 425px) {
  .gl_nav--btn-neo {
    order: 1;
  }
  .gl_nav--btn-contact {
    order: 2;
  }
  .gl_nav--btn-recruit {
    display: none;
  }
  .gl_nav--btn-download {
    display: flex;
    margin-bottom: 10px;
  }
}

/* メニュー代替版 */
/* 上の階層にmenu_altクラスがある場合に表示 */
.menu_alt .gl_nav--btn-maeq {
  display: none;
}

.menu_alt .gl_nav--btn-neo {
  order: 3;
}

.menu_alt .gl_nav--btn-contact {
  display: none;
}

.menu_alt .gl_nav--btn-recruit {
  display: flex;
  order: 1;
}

@media (max-width: 1300px) {
  .menu_alt .gl_nav--btn-download {
    display: flex;
  }
}

@media (max-width: 425px) {
  .menu_alt .gl_nav--btn-maeq {
    display: flex;
    order: 1;
    margin-bottom: 10px;
  }
  .menu_alt .gl_nav--btn-neo {
    order: 2;
  }
  .menu_alt .gl_nav--btn-contact {
    display: none;
  }
  .menu_alt .gl_nav--btn-recruit {
    display: flex;
    order: 2;
  }
}

/* トップページ例外 */
.asbank_index .gl_nav.opened {
  height: calc(100vh - 64px - 49px);
}

.asbank_index .gl_nav.opened .gl_nav--contents {
  height: calc(100% - 64px - 49px);
}

@media (max-width: 425px) {
  .asbank_index .gl_nav.opened {
    height: calc(100vh - 50px - 46px);
  }
  .asbank_index .gl_nav.opened .gl_nav--contents {
    height: calc(100% - 50px - 46px);
  }
}

/*
'Noto Sans JP' -> main 
'Anton' -> tel, step-num
'Teko' -> faq label
'Lato' -> copyright
*/
body, html {
  font-family: 'Noto Sans JP',sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: #fff;
  opacity: 1;
  transition: opacity .3s linear .3s;
}

body.fade {
  opacity: 0;
}

/*
a{
  color:#000;
  text-decoration:none;
  &:hover{
    color:$base-red;
    text-decoration:underline;
  }
 }
*/
p {
  line-break: strict;
}

/* 中面ページ共通 */
.cnt {
  margin: 64px 0 0;
  width: 100%;
  min-width: 980px;
  overflow: hidden;
}

@media (max-width: 425px) {
  .cnt {
    margin: 50px 0 0;
    min-width: 0;
  }
}

/* パンくずリスト */
.cnt_bread {
  width: 960px;
  padding: 0;
  margin: 40px auto 64px;
}

@media (max-width: 425px) {
  .cnt_bread {
    width: 100%;
    margin: 20px 0 48px;
    padding: 0 30px;
    box-sizing: border-box;
  }
}

@media (max-width: 320px) {
  .cnt_bread {
    padding: 0 20px;
  }
}

.cnt_bread--list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.cnt_bread--item {
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  color: #c80023;
}

.cnt_bread--item:not(:last-of-type):after {
  color: #000;
  content: '/';
  margin: 0 0.5em;
}

.cnt_bread--item a {
  color: #000;
  text-decoration: none;
}

.cnt_bread--item a:hover {
  color: #c80023;
  text-decoration: underline;
}

/* ページ下端ポップアップバナー */
.cnt_banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 575px;
  height: 86px;
  left: calc(50% - 287px);
  bottom: -86px;
  background: #c80023;
  z-index: 99;
  padding: 0 30px 0 30px;
  box-sizing: border-box;
  transition: bottom .5s ease-out;
}

@media (max-width: 425px) {
  .cnt_banner {
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    left: 0;
    bottom: -100px;
    padding: 10px 20px;
  }
}

@media (max-width: 320px) {
  .cnt_banner {
    padding: 10px;
  }
}

.cnt_banner--tel {
  font-family: 'Anton',sans-serif;
  font-size: 30px;
  line-height: 1.3;
  text-decoration: none;
  color: #fff;
  text-align: center;
}

@media (max-width: 425px) {
  .cnt_banner--tel {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 3px;
  }
}

@media (max-width: 320px) {
  .cnt_banner--tel {
    font-size: 24px;
  }
}

.cnt_banner--tel:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-decoration: none;
  text-transform: none;
  content: "";
  font-size: 27px;
  vertical-align: baseline;
  margin-right: 0.5em;
}

@media (max-width: 425px) {
  .cnt_banner--tel:before {
    margin-right: 0.3em;
  }
}

@media (max-width: 320px) {
  .cnt_banner--tel:before {
    font-size: 26px;
    margin-right: 0.2em;
  }
}

.cnt_banner--span {
  display: block;
  font-family: 'Noto Sans JP',sans-serif;
  font-size: 12px;
}

@media (max-width: 425px) {
  .cnt_banner--span {
    text-align: left;
    margin-left: 10px;
  }
}

.cnt_banner--btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #c80023;
  text-decoration: none;
  background: #fff;
  width: 280px;
  height: 50px;
  border: 1px solid #c80023;
  transition: all .2s linear;
}

@media (max-width: 425px) {
  .cnt_banner--btn {
    font-size: 14px;
    width: 100%;
    height: 34px;
    margin: auto;
  }
}

.cnt_banner--btn:after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-decoration: none;
  text-transform: none;
  content: "";
  font-size: 14px;
  margin: 0 -7px 0 7px;
}

@media (max-width: 425px) {
  .cnt_banner--btn:after {
    position: absolute;
    right: 10px;
    margin: 0;
  }
}

.cnt_banner--btn:hover {
  color: #fff;
  border-color: #fff;
  background: #c80023;
}

.cnt_banner.show {
  bottom: 0;
}

/* 汎用ボタン */
.cnt_common--btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  min-height: 51px;
  color: #c80023;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  letter-spacing: -0.06em;
  padding: 16px 0;
  margin: 0;
  border: 1px solid #c80023;
  background-color: #fff;
  box-sizing: border-box;
  transition: .3s;
}

.cnt_common--btn:after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-decoration: none;
  text-transform: none;
  content: "";
  font-size: 14px;
  display: block;
  position: absolute;
  top: calc(50% - 7px);
  right: 10px;
  vertical-align: middle;
}

.cnt_common--btn:hover {
  background-color: #c80023;
  color: #fff;
}

.cnt_common--btn-double {
  font-size: 12px;
  line-height: 14px;
}

/* スマートフォンのみ表示 */
.sp {
  display: none;
}

@media (max-width: 425px) {
  .sp {
    display: block;
  }
}

/* スマートフォンのみ非表示 */
.nosp {
  display: block;
}

@media (max-width: 425px) {
  .nosp {
    display: none;
  }
}

@media (min-width: 768px) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}
