@charset "UTF-8";
@import "mixin";

/* グローバルナビ */
.gl_nav {
  position: fixed;
  top:64px;
  right:0;
  width:0;
  height:0;
  overflow:hidden;
  opacity:0;
  transition:all .2s linear;
  z-index: 110;
  box-sizing:border-box;
  @include sp{
    top:50px;
  }
  &.opened{
    height:calc(100vh - 64px);
    width:100%;
    opacity:1;
    @include sp{
      height:calc(100vh - 50px);
    }
    & .gl_nav--contents{
      visibility:visible;
      width:100%;
      height:calc(100% - 64px);
      @include sp{
        height:calc(100% - 50px);
      }
    }
  }
  &--contents {
    display: flex;
    visibility:hidden;
    justify-content:center;
    align-items:flex-start;
    position: fixed;
    top:64px;
    right:0;
    width:100%;
    height:0;
    background-color: #fff;
    padding-top:115px;
    box-sizing:border-box;
    transition:all .2s linear;
    @include sp{
      flex-direction:column;
      justify-content:flex-start;
      top:50px;
      padding:40px 30px 30px;
    }
    @include se{
      padding:20px;
    }
    &:before,
    &:after{
      /* 背景矢印 */
      content:'';
      position:absolute;
      width:457px;
      height:50%;
      left:50%;
      opacity:.1;
      z-index:0;
    }
    &:before{
      background : linear-gradient(160deg, rgba(234, 97, 123, 1) 0%, rgba(227, 66, 95, 1) 17.88%, rgba(221, 37, 69, 1) 38.82%, rgba(217, 17, 50, 1) 59.68%, rgba(214, 4, 39, 1) 80.2%, rgba(213, 0, 35, 1) 100%);
      transform-origin:left bottom;
      transform:skew(-124.5deg);
      bottom:50%;
    }
    &:after{
      background : linear-gradient(50deg, rgba(242, 175, 175, 1) 0%, rgba(241, 163, 167, 1) 8.14%, rgba(238, 134, 148, 1) 31.57%, rgba(236, 114, 134, 1) 54.9%, rgba(234, 101, 126, 1) 77.85%, rgba(234, 97, 123, 1) 100%);
      top:50%;
      transform-origin:left top;
      transform:skew(124.5deg);
    }
  }
  &--list{
    position:relative;
    z-index:1;
    @include sp{
      display:flex;
      flex-direction:column;
      justify-content:space-between;
      align-items:flex-start;
      flex-grow:1;
      margin-bottom:40px;
      max-height:250px;
    }
  }
  &--item{
    font-family:'Noto Sans JP',sans-serif;
    font-size:24px;
    font-weight:500;
    margin-bottom:72px;
    @include sp{
      font-size:18px;
      margin-bottom:0;
    }
    @include se{
      font-size:16px;
    }
    &-link{
      display:block;
      color:inherit;
      text-decoration:none;
      &:hover{
        text-decoration:underline;
      }
    }
  }
  &--right{
    position:relative;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    text-align:center;
    margin-left:115px;
    z-index:1;
    @include sp{
      margin-left:0;
      justify-content:space-between;
      align-items:flex-start;
      flex-direction:row;
      flex-wrap:wrap;
    }
  }
  &--txt{
    font-size:14px;
    line-height:1;
    font-weight:500;
    text-align:center;
    margin:32px 0;
    order:2;
    @include sp{
      margin:20px 0 0;
      order:3;
      text-align:left;
    }
  }
  &--tel{
    display:inline-block;
    color:inherit;
    text-decoration:none;
    font-family:'Anton',sans-serif;
    font-size:34px;
    line-height:1;
    margin:18px 0;
    @include sp{
      margin:18px 0 8px;
    }
  }
  &--btn {
    position: relative;
    display: inline-flex;
    justify-content:center;
    align-items:center;
    min-width:221px;
    min-height:51px;
    color: $base-red;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 16px;
    line-height:1;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    letter-spacing:-0.06em;
    padding:16px 0;
    margin:0;
    border:1px solid $base-red;
    background-color: #fff;
    box-sizing:border-box;
    transition: .3s;
    @include sp{
      font-size:14px;
      min-width:0;
      min-height:62px;
      width:48%;
    }
    @include se{
      font-size:13px;
      min-height:54px;
      padding:10px 0;
      width:49%;
    }
    &:after {
      @include icon;
      content:$icon-arrow-right;
      font-size:14px;
      display:block;
      position: absolute;
      top: calc(50% - 7px);
      right: 10px;
      vertical-align: middle;
      @include se{
        font-size:12px;
        top:calc(50% - 6px);
        right:6px;
      }
    }
    &:hover {
        background-color: $base-red;
        color:#fff;
    }
    &-double{
      font-size:12px;
      line-height:14px;
    }
  }
  &--btn-maeq{display:none;}
  &--btn-neo{order:1;}
  &--btn-contact{order:3;}
  &--btn-recruit{display:none;}
  &--btn-download{display:none;margin-bottom:10px;}
  @include sp{
    &--btn-neo{order:1;}
    &--btn-contact{order:2;}
    &--btn-recruit{display:none;}
    &--btn-download{display:flex;margin-bottom:10px;}
  }
}

/* メニュー代替版 */
/* 上の階層にmenu_altクラスがある場合に表示 */
.menu_alt .gl_nav{
  &--btn-maeq{display:none;}
  &--btn-neo{order:3;}
  &--btn-contact{display:none;}
  &--btn-recruit{display:flex;order:1;}
  @media(max-width:1300px){
    &--btn-download{display:flex;}
  }
  @include sp{
    &--btn-maeq{display:flex;order:1;margin-bottom:10px;}
    &--btn-neo{order:2;}
    &--btn-contact{display:none;}
    &--btn-recruit{display:flex;order:2;}
  }
}

/* トップページ例外 */
.asbank_index .gl_nav{
  &.opened{
    height:calc(100vh - 64px - 49px);
    & .gl_nav--contents{
      height:calc(100% - 64px - 49px);
    }
  }
  @include sp{
    &.opened{
      height:calc(100vh - 50px - 46px);
      & .gl_nav--contents{
        height:calc(100% - 50px - 46px);
      }
    }
  }
}