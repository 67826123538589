@charset "UTF-8";

@import "mixin";
@import "icon";
@import "common/header";
@import "common/footer";
@import "common/navi";

// google font
@import url('https://fonts.googleapis.com/css?family=Anton|Lato:300|Noto+Sans+JP:400,500|Teko:500&display=swap');
/*
'Noto Sans JP' -> main 
'Anton' -> tel, step-num
'Teko' -> faq label
'Lato' -> copyright
*/

body,html{
  font-family:'Noto Sans JP',sans-serif;
  font-size:16px;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  background:#fff;
  opacity:1;
  transition:opacity .3s linear .3s;
  &.fade{
    opacity:0;
  }
}

/*
a{
  color:#000;
  text-decoration:none;
  &:hover{
    color:$base-red;
    text-decoration:underline;
  }
 }
*/

p{
  line-break:strict;
}

/* 中面ページ共通 */
.cnt{
  margin:64px 0 0;
  width:100%;
  min-width:980px;
  overflow:hidden;
  @include sp{
    margin:50px 0 0;
    min-width:0;
  }
}

/* パンくずリスト */
.cnt_bread{
  width:960px;
  padding:0;
  margin:40px auto 64px;
  @include sp{
    width:100%;
    margin:20px 0 48px;
    padding:0 30px;
    box-sizing:border-box;
  }
  @include se{
    padding:0 20px;
  }
  &--list{
    display:flex;
    justify-content:flex-start;
    align-items:center;
  }
  &--item{
    font-size:12px;
    line-height:1;
    font-weight:500;
    color:$base-red;
    &:not(:last-of-type):after{
      color:#000;
      content:'/';
      margin:0 0.5em;
    }
    & a{
      color:#000;
      text-decoration:none;
      &:hover{
        color:$base-red;
        text-decoration:underline;
      }
    }
  }
}

/* ページ下端ポップアップバナー */
.cnt_banner{
  display:flex;
  justify-content:space-between;
  align-items:center;
  position:fixed;
  width:575px;
  height:86px;
  left:calc(50% - 287px);
  bottom:-86px;
  background:$base-red;
  z-index:99;
  padding:0 30px 0 30px;
  box-sizing:border-box;
  transition:bottom .5s ease-out;
  @include sp{
    flex-wrap:wrap;
    width:100%;
    height:auto;
    left:0;
    bottom:-100px;
    padding:10px 20px;
  }
  @include se{
    padding:10px;
  }
  &--tel{
    font-family:'Anton',sans-serif;
    font-size:30px;
    line-height:1.3;
    text-decoration:none;
    color:#fff;
    text-align:center;
    @include sp{
      display:flex;
      justify-content:center;
      align-items:center;
      margin:0 auto 3px;
    }
    @include se{
      font-size:24px;
    }
    &:before{
      @include icon;
      content:$icon-tel;
      font-size:27px;
      vertical-align:baseline;
      margin-right:0.5em;
      @include sp{
        margin-right:0.3em;
      }
      @include se{
        font-size:26px;
        margin-right:0.2em;
      }
    }
  }
  &--span{
    display:block;
    font-family:'Noto Sans JP',sans-serif;
    font-size:12px;
    @include sp{
      text-align:left;
      margin-left:10px;
    }
  }
  &--btn{
    position:relative;
    display:flex;
    justify-content:center;
    align-items:center;
    color:$base-red;
    text-decoration:none;
    background:#fff;
    width:280px;
    height:50px;
    border:1px solid $base-red;
    transition: all .2s linear;
    @include sp{
      font-size:14px;
      width:100%;
      height:34px;
      margin:auto;
    }
    &:after{
      @include icon;
      content:$icon-arrow-right;
      font-size:14px;
      margin:0 -7px 0 7px;
      @include sp{
        position:absolute;
        right:10px;
        margin:0 ;
      }
    }
    &:hover{
      color:#fff;
      border-color:#fff;
      background:$base-red;
    }
  }
  &.show{bottom:0;}
}


/* 汎用ボタン */
.cnt_common--btn{
  position: relative;
  display: inline-flex;
  justify-content:center;
  align-items:center;
  min-width:200px;
  min-height:51px;
  color: $base-red;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  line-height:1;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  letter-spacing:-0.06em;
  padding:16px 0;
  margin:0;
  border:1px solid $base-red;
  background-color: #fff;
  box-sizing:border-box;
  transition: .3s;
  &:after {
    @include icon;
    content:$icon-arrow-right;
    font-size:14px;
    display:block;
    position: absolute;
    top: calc(50% - 7px);
    right: 10px;
    vertical-align: middle;
  }
  &:hover {
      background-color: $base-red;
      color:#fff;
  }
  &-double{
    font-size:12px;
    line-height:14px;
  }
}

/* スマートフォンのみ表示 */
.sp{
  display:none;
  @include sp{
    display:block;
  }
}
/* スマートフォンのみ非表示 */
.nosp{
  display:block;
  @include sp{
    display:none;
  }
}

// スマートフォンでタップした時だけ電話発信を有効にする
@media(min-width: 768px){
  a[href^="tel:"]{
    pointer-events: none;
  }
}
