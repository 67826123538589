@charset "UTF-8";

// #clearfix
@mixin clearfix {
  &:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    font-size: 0;
    content: '.';
  }
}

//color 
$base-red: #c80023;

// web icon fontを使う場合
@mixin icon_ {
  font-family: "icomoon" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// sp
@mixin sp {
  @media (max-width: 425px) {
    @content;
  }
}
// tablet
@mixin tb {
  @media (max-width: 768px) {
    @content;
  }
}
// laptopPC
@mixin lt {
  @media (max-width: 1340px) {
    @content;
  }
}

// iPhone SE
@mixin se {
  @media (max-width:320px) {
    @content;
  }
}