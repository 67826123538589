
// SCSSファイルを直接編集しないでください。SVGファイルを追加すると自動生成されます。
// 編集する必要がある場合は、テンプレートを編集してください。
// アイコンフォントを使用するためのmixinが記述されています。
// 例: module.scssなど
// *注意* contentには必ず変数を使用してください。(自動採番のため、値が変動します))
// @import "_icon.scss";
// .this-is-icon-class {
//   &:before {
//     @include icon;
//     content: $icon-XXX; (XXXはSVGファイル名から拡張子を取り除いた文字)
//     color: XXX;
//     font-size: XXX;
//   }
// }
@font-face {
	font-family: "iconfont";
	src: url('/common/fonts/iconfont.eot');
	src: url('/common/fonts/iconfont.eot?#iefix') format('eot'),
		url('/common/fonts/iconfont.woff2') format('woff2'),
		url('/common/fonts/iconfont.woff') format('woff'),
		url('/common/fonts/iconfont.ttf') format('truetype'),
		url('/common/fonts/iconfont.svg#iconfont') format('svg');
}

@mixin icon {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-decoration: none;
  text-transform: none;
}


$icon-arrow-left: '\E001';

$icon-arrow-right: '\E002';

$icon-arrow-select: '\E003';

$icon-asset-check: '\E004';

$icon-asset-comp: '\E005';

$icon-asset-follow: '\E006';

$icon-asset-human: '\E007';

$icon-asset-match: '\E008';

$icon-asset-step: '\E009';

$icon-facebook: '\E00A';

$icon-instagram: '\E00B';

$icon-line: '\E00C';

$icon-slider-bullet: '\E00D';

$icon-tel: '\E00E';

$icon-twitter: '\E00F';

$icon-type-driver: '\E010';

$icon-type-lift: '\E011';

$icon-type-worker: '\E012';
