@charset "UTF-8";
@import "mixin";

/* footer */
.gl_footer {
  position:relative;
	width: 100%;
  min-width:980px;
  color:#fff;
  background: linear-gradient(0deg, rgba(65, 65, 68, 1) 0%, rgba(0, 0, 0, 1) 37.07%);
  padding:100px 0 50px;
  @include sp{
    min-width:0;
    padding:50px 28px 120px;
    box-sizing:border-box;
  }
  @include se{
    padding:40px 20px 120px;
  }
  &--panels{
    width:960px;
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
    flex-wrap:wrap;
    margin:0 auto 86px;
    @include sp{
      justify-content:center;
      width:100%;
      margin-bottom:66px;
    }
    & li{
      margin-bottom:48px;
      @include sp{
        width:100%;
        margin-bottom:20px;
      }
    }
  }
  @include sp{
    &--item01{order:1;}
    &--item02{order:2;}
    &--item03{order:4;}
    &--item04{order:3;}
    &--item04wide{order:3;width:auto;}
  }
  &--panel{
    position:relative;
    color:inherit;
    font-size:24px;
    font-weight:500;
    line-height:34px;
    text-decoration:none;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    width:450px;
    height:250px;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center center;
    /*border:8px solid transparent;*/
    outline:8px solid transparent;
    outline-offset:-8px;
    box-sizing:border-box;
    transition:all .2s linear;
    overflow:hidden;
    @include sp{
      font-size:18px;
      line-height:24px;
      width:auto;
      height:120px;
      outline-offset:-4px;
      outline-width:4px;
    }
    &:hover{
      /*border-color:$base-red;*/
      outline-color:$base-red;
    }
    &:after{
      @include icon;
      content:$icon-arrow-right;
      font-size:22px;
      color:$base-red;
      display:block;
      margin:0 -20px 0 20px;
      @include sp{
        font-size:16px;
        margin:0 -10px 0 10px;
      }
    }
    &-01{background-image:url('/common/images/footer-panel01.jpg');}
    &-02{background-image:url('/common/images/footer-panel02.jpg');}
    &-03{background-image:url('/common/images/footer-panel03.jpg');}
    &-04{background-image:url('/common/images/footer-panel04.jpg');}
    &-04wide{background-image:url('/common/images/footer-panel04-wide.jpg');width:960px;}
  }
  &--info{
    justify-content:space-between;
    width:960px;
    display:flex;
    align-items:flex-start;
    margin:0 auto 100px;
    @include sp{
      width:auto;
      flex-wrap:wrap;
      margin-bottom:75px;
    }
  }
  &--list{
    font-size:14px;
    font-weight:600;
    @include se{
      font-size:13px;
    }
    & li:not(:last-of-type){
      margin:0 0 18px;
    }
    & a{
      color:inherit;
      text-decoration:none;
      &:hover{
        text-decoration:underline;
      }
    }
    &-sub{
      margin:24px 0 0;
      @include sp{
        margin-top:18px;
      }
      & li{
        color:rgb(179,179,179);
        font-weight:500;
        &:before{
          content:'- ';
          color:$base-red;
        }
      }
    }
  @include sp{
    &:first-of-type{
      margin-bottom:45px;
    }
  }
  }
  &--logo{
    text-decoration:none;
    /*margin-top:-10px;*/
    margin-right:90px;
    @include sp{
      margin:0 90px 40px 0;
    }
    & img{
      height:70px;
      @include sp{
        height:50px;
      }
    }
  }
  &--sns{
    display:flex;
    justify-content:center;
    flex-wrap:wrap;
    & a{
      text-decoration:none;
      margin:0 15px;
    }
  }
  &--facebook:before{
    @include icon;
    content:$icon-facebook;
    font-size:30px;
    color:#fff;
  }
  &--instagram:before{
    @include icon;
    content:$icon-instagram;
    font-size:30px;
    color:#fff;
  }
  &--line:before{
    @include icon;
    content:$icon-line;
    font-size:30px;
    color:#fff;
  }
  &--twitter:before{
    @include icon;
    content:$icon-twitter;
    font-size:30px;
    color:#fff;
  }
  &--copyright{
    width:100%;
    font-family:'Lato',sans-serif;
    font-size:14px;
    font-weight:200;
    text-align:center;
    margin:40px 0 0;
    @include sp{
      font-size:13px;
      margin:40px -20px 0;
    }
    @include se{
      font-size:11px;
    }
  }
  &--pagetop{
    display:flex;
    justify-content:center;
    align-items:center;
    position:fixed;
    right:48px;
    bottom:36px;
    width:90px;
    height:90px;
    background:#fff;
    border-radius:45px;
    border:8px solid #bababa;
    z-index:2;
    box-sizing:border-box;
    transition:all .3s linear;
    visibility:visible;
    opacity:1;
    &.hidden{
      opacity:0;
      visibility:hidden;
    }
    &:hover{
      border-color:$base-red;
    }
    & img{
      width:31px;
      margin-top:-6px;
      @include sp{
        width:20px;
        margin-top:-3px;
        -webkit-tap-highlight-color:transparent;
      }
    }
    @include sp{
      right:16px;
      bottom:38px;
      width:56px;
      height:56px;
      border-width:5px;
      -webkit-tap-highlight-color:transparent;
      &:hover{border-color:#bababa;}
      &.moved{
        bottom:108px;
      }
    }
  }
}


/* 採用情報の表示／非表示 */
/* 上位に.norecruitクラスを設定して切替 */
.gl_footer--norecruit{
  &-hide{display:block;}
  &-show{display:none;}
}
.norecruit .gl_footer--norecruit{
  &-hide{display:none;}
  &-show{display:block;}
}
